.toc {
  h1, h2, h3, h4 { margin-top: 0 !important }
  @include media-breakpoint-up("md") {
    background: $light;
    box-shadow: #ccc 3px 3px 3px;
  }

  ul, ol {
    margin: 0;
    list-style-type: decimal;
    list-style-position: inside;
    padding-left: initial;
    li {
      margin-left: 0;
    }
    li li {
      margin-left: 1rem;
    }
  }
}
