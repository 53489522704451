$facilitators: (
  "primary": #eea62b,
  "secondary": #f8d68c,
  "light": #fdf4e6,
);

$participants: (
  "primary": #74bccd,
  "secondary": #9bd7eb,
  "light": #eef7f9,
  "virtual-event": #6f42c1,
  "onsite-event": #28a745,
);

$navbar-light-color: rgba(0, 0, 0, 0.8);
