.ratio-16-9 {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (9 / 16) * 100%;
    }
    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }