.read-more p.collapse:not(.show) {
  height: 7.5em !important;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.read-more p.collapsing {
  min-height: 7.5em !important;
}

.read-more a.collapsed:after {
  content: "Read More";
}

.read-more a:not(.collapsed):after {
  content: "Read Less";
}
