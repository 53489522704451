@each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
        .display-1 {
            font-size: $display1-size*($container-max-width/1140px);
        }
        .display-2 {
            font-size: $display2-size*($container-max-width/1140px);
        }
        .display-3 {
            font-size: $display3-size*($container-max-width/1140px);
        }
        .display-4 {
            font-size: $display4-size*($container-max-width/1140px);
        }
    }
}

@include media-breakpoint-down("sm") {
    $min: map-get($container-max-widths, "sm");
    .display-1 {
        font-size: $display1-size*($min/1140px);
    }
    .display-2 {
        font-size: $display2-size*($min/1140px);
    }
    .display-3 {
        font-size: $display3-size*($min/1140px);
    }
    .display-4 {
        font-size: $display4-size*($min/1140px);
    }
}